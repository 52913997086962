import Button from "@/components/atoms/Button";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";

type Props = {};

const Err = (props: Props) => {
  const [rng, setRNG] = useState(0);

  useEffect(() => {
    setRNG(Math.floor(Math.random() * 100));
  }, []);

  return (
    <div
      className={`relative flex  h-screen flex-col items-center justify-center ${
        rng === 7 ? "bg-primary-main" : "bg-neutral-300"
      }`}
    >
      <div
        className={`pb-6 text-9xl text-white-0 ${
          rng === 7 ? "animate-bounce" : ""
        }`}
      >
        404
      </div>
      <Link href={"/homepage"}>
        <Button size="sm">Kembali ke homepage</Button>
      </Link>
    </div>
  );
};

export default Err;
